import React from "react";

export default () => {

    return (
        <React.Fragment>
            {/* <footer className="webView">
                <div className="row ">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <p>All right reserved RxLink 2023</p>
                    </div>
                </div>
            </footer> */}
        </React.Fragment>
    );
};