
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { UsernameField, usernameValidation } from "../Fields/Username";
import { useRef } from "react";
import TickCross from "../../utilities/TickCross";
import MaskedInput from "react-text-mask";

import { ariaAnnounce } from "../../utilities/useAnnounceText";


export default (props) => {
  const [mask, setMask] = useState("")
  const [modalIsOpen, setModalIsOpen] = useState(props.show);
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [isOpenResetPwdModal, setIsOpenResetPwdModal] = useState(false);
  var numberPattern = /\d+/g;
  const userNameRef = useRef(null);
  useEffect(() => {
    // console.log(modalIsOpen);
  }, [modalIsOpen]);

  useEffect(() => {
    ariaAnnounce("Forgot Password")
  }, [])

  const changePasswordType = () => {
    setPasswordType(passwordType == "password" ? "text" : "password");
  };

  const changeConfirmPasswordType = () => {
    setConfirmPasswordType(
      confirmPasswordType == "password" ? "text" : "password"
    );
  };

  const validationSchema = Yup.object().shape({
    username: usernameValidation,
  });

  const formik = useFormik({
    initialValues: {
      username: '',

    },
    validationSchema,
    // validateOnChange: false,
    // validateOnBlur: false,
    onSubmit: (data) => {
      let body = {
        number: data.username.match(numberPattern).join(""),
      };
      props.setSubmitForgotPasswordData(body);
    },
  });

  const setResetPwd = (value) => {
    setIsOpenResetPwdModal(value);
  }

  const { isSubmitting, isValidating, resetForm } = formik;

  useEffect(() => {
    if (isSubmitting) {
      if (Object.keys(formik.errors).length > 0) {
        const firstErrorField = Object.keys(formik.errors).find(
          (fieldName) => formik.touched[fieldName]
        );
        if (firstErrorField) {
          if (firstErrorField === "username") {
            window.document.getElementById("forgotPasswordPhoneInput").focus();
            ariaAnnounce(formik.errors.username, "resetPasswordForm1")
          }
        }
      }
    }
  }, [isSubmitting, isValidating])

  useEffect(() => {
    if (!props.show) {
      resetForm();
      setMask("");
    }
  }, [props.show])


  const resetFormFields = () => {
    resetForm();
    setMask("");
  };


  return (
    <React.Fragment>
      <Modal
        show={props.show}
        onHide={props.close}
        keyboard={false}
        centered
        aria-labelledby="forgotPasswordPopup"
        aria-describedby="resetPasswordForm1"
      >
        {/* <Modal.Header closeButton>
                    <Modal.Title>Modal title</Modal.Title>
                </Modal.Header> */}
        <Modal.Body>
          <section className="forget_section">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                  <p id="forgotPasswordPopup" style={{ color: "#416e35", fontWeight: "600" }}>
                    Forgot Password
                  </p>
                </div>
              </div>
              <form onSubmit={formik.handleSubmit} id="resetPasswordForm1">
                <div className="forget_box" >
                  {/* <div className="row x">
                    <div className="text-end">
                      <button
                        className="crossButton"
                        onClick={props.close}
                        type="button"
                      >
                        <img src={cancelIcon} />
                      </button>
                    </div>
                  </div> */}

                  {/* <UsernameField
                    formik={formik}
                    mask={mask}
                    setMask={setMask}
                    id="forgotPasswordPhoneInput"
                    ariaLabel="Mobile Phone Number"
                  /> */}
                  <div className="forget_input_eye">

                    {/* <InputMask
                      name='username'
                      className={`form-control  custom-form-control ${true && "disabled-input"}` +
                        (formik.errors.username && formik.touched.username
                          ? " is-invalid"
                          : "")}
                      mask="(999) 999-9999"
                      maskChar={null}
                      placeholder="Mobile phone number"
                      onChange={formik.handleChange}
                      value={formik.values.username}
                      aria-label="Mobile Phone Number"
                      id="forgotPasswordPhoneInput"
                    >
                    </InputMask> */}
                    <MaskedInput
                      name="username"
                      className={`form-control custom-form-control ${true && "disabled-input"}` +
                        (formik.errors.username && formik.touched.username ? " is-invalid" : "")}
                      mask={["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
                      placeholder="Mobile phone number"
                      onChange={formik.handleChange}
                      value={formik.values.username}
                      aria-label="Mobile Phone Number"
                      // ref={usernameRef} // Use ref instead of inputRef
                      id="forgotPasswordPhoneInput"
                    />
                    {formik.errors.username && formik.touched.username && <div className="invalid-feedback" aria-live="off" id="usernameError">
                      {formik.errors.username && formik.touched.username
                        ? formik.errors.username
                        : null}
                    </div>}
                    {TickCross(formik, "username", "error-circle")}

                  </div>

                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      {/* <MotionButton> */}
                      <button
                        type="submit"
                        className="btn_success "
                        form="resetPasswordForm1" onClick={() => setResetPwd(true)}
                      >
                        Submit
                      </button>
                      {/* </MotionButton> */}
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        {/* <MotionButton> */}
                        <button
                          type="button"
                          className="btn_default mt-2"
                          onClick={() => {
                            resetFormFields()
                            props.close()
                          }}
                        >
                          Cancel
                        </button>
                        {/* </MotionButton> */}
                      </div>
                    </div>
                  </div>
                  <div className="row">

                  </div>
                </div>
              </form>
            </div>
          </section>
        </Modal.Body>
        {/* <Modal.Footer>
                    <Button variant="secondary" >
                        Close
                    </Button>
                    <Button variant="primary">Understood</Button>
                </Modal.Footer> */}
      </Modal>
    </React.Fragment>
  );
};
