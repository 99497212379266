import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import Tablet from "../../assets/images/pill.png";
import Liquid from "../../assets/images/liquid.png";
import trash from "../../assets/images/RxLink assets/new assets/Trash_light.svg";
import InfoSvg from "./../../assets/images/info.svg";
import {
  motion,
  AnimatePresence,
} from "framer-motion";
import CPDImg from "../../assets/images/CPD_logo.jpg";
import Tooltip from 'react-bootstrap/Tooltip';
import { Popover } from "react-bootstrap";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { httpClientConnective } from "../../environment";
import { searchPharmacyLogo, searchPharmacyType } from "../PharmacyLogo";
import { isMobile } from "react-device-detect";
import LogoComponent from "../LogoComponent";
import LoadingComponent from "../LoadingComponent";
import { useGetUser } from "../../utilities/useGetUser";
import EditMedicationModal from "./EditMedicationModal";
import { setDrugSelected } from "../../reducers/global";
import InfiniteScroll from "react-infinite-scroll-component";
import Spinner from 'react-bootstrap/Spinner';
import { normalizeCompanyName } from "../../utilities/getNormalizedName";
import { StringToHTML } from "../../utilities/StringToHtml";
import NewTabRedirector from "../../utilities/NewTabRedirector";

export default ({
  insuranceInfo,
  hasInsurance,
  npi,
  param,
  resetDrugDetails,
  medicationsParam,
  totalValuesParam,
  savingOptionParam,
  deleteShowParam,
  refParam,
  setMedAndDrugPrices,
  setMedicationsLength,
  setSavingOptionsPram,
  ownPosition,
  setOriginalFilter,
  setPharmacyFlag,
  valParam,
  setPharmacyData,
  setFinalPrices,
  pharmacyParam,
  medicationInfo,
  setMedicationInfo,
  setAddOrUpdateMed,
  quantity,
  setQuantity,
  label,
  setLabel,
  form,
  setForm,
  dosage,
  setDosage,
  setRemoveDrug,
  bestPackage,
  medicationList,
  fullMedicinesList,
  fetchMoreMedicines,
  medicationsLength,
  addingMedicine
}) => {
  const [medications, setMedications] = useState([]);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [viewSavingOptions, setSavingOptions] = useState(false);
  const [selectMapValues, setSelectMapValues] = useState([]);
  const [deleteShow, setDeleteShow] = useState(false);
  const [isVisibleEditModal, setIsVisibleEditModal] = useState(false);
  const [showLocalPharmacies, setShowLocalPharmacies] = useState(false);
  const [openedTabs, setOpenedTabs] = useState({});

  const patientInsuranceInfo = useSelector((state) => state.global.insuranceInfo);
  const selectedDrug = useSelector((state) => state.global.selectedDrug);
  const disclaimer = useSelector((state) => state.copayPrices.disclaimer);

  const setSelectedDrug = (drug) => {
    dispatch(setDrugSelected(drug));
  }

  const tooltipRef = useRef(null);
  const userDetail = useGetUser();

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && tooltipRef.current) {
        tooltipRef.current.hide();
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (showLocalPharmacies) {
      setTimeout(() => {
        const element = document.getElementById("localPharmaciesList");
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 200)
    }
  }, [showLocalPharmacies])

  useEffect(() => {
    setSavingOptions(savingOptionParam);
    setSavingOptions(savingOptionParam);
  }, [savingOptionParam]);

  useEffect(() => {
    if (viewSavingOptions) {
      const element = document.getElementById("listingContent");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [viewSavingOptions]);

  useEffect(() => {
    setDeleteShow(deleteShowParam);
  }, [deleteShowParam]);

  useEffect(() => {
    medicationsParam(medications);
    if (medications?.length === 0) {
      setMedicationsLength(0);
    }
  }, [medications]);

  useEffect(() => {
    if (selectMapValues?.length !== 0) {
      totalValuesParam(selectMapValues);
    }
  }, [selectMapValues]);


  const userHasPharmacy = userDetail?.pharmacy?.userPharmacySeqNo;
  const userPharmacy = userDetail?.pharmacy;

  const savePrescription = (obj, names, drugQuantities) => {
    let drugType = names?.filter((name) => name.name.toLowerCase() == obj?.Name.toLowerCase())?.[0]?.type;
    let request = {
      appUserSeqNo: JSON.parse(localStorage.getItem("user"))?.appUserSeqNo,
      drugSeqNo: selectedDrug?.drugSeqNo,
      name: obj?.Name,
      type: drugType ? drugType : "",
      search_name: obj?.Name,
      dosage: obj?.Dosage,
      form: obj?.Form,
      form_plural: obj?.Form,
      package_size: obj?.PackageQuantity,
      quantities: drugQuantities,
      default_quantity: obj?.Quantity,
      names: names.map((name) => {
        return name.name
      }),
      ndc: obj?.NDC,
      description: obj?.Description,
      insurance: patientInsuranceInfo?.bin && patientInsuranceInfo?.groupNumber && patientInsuranceInfo?.memberId ? {
        bin: patientInsuranceInfo?.bin,
        groupNumber: patientInsuranceInfo?.groupNumber,
        memberId: patientInsuranceInfo?.memberId,
        pcn: patientInsuranceInfo?.pcn
      } : null,

      dob: JSON.parse(localStorage.getItem("user"))?.dob,
      pharmacyState: userHasPharmacy ? userPharmacy.state : ownPosition?.responseZIP?.region_code
    };
    setAddOrUpdateMed(request);
    setIsVisibleEditModal(false);
  };

  const removeMedication = (index, drugSeqNo) => {
    if (medicationInfo?.length === 1) {
      sessionStorage.removeItem("selectMapValues");
    }
    setRemoveDrug({ index: index, drugSeqNo: drugSeqNo });
  };

  const selectMap = (sub_element) => {
    setPharmacyFlag(true);
    setSavingOptions(false);
    setSavingOptionsPram(false);

    setSelectMapValues(Object.assign({}, sub_element));
    setPharmacyData(Object.assign({}, sub_element));
    sessionStorage.setItem("selectMapValues", JSON.stringify(sub_element));
  };

  const selectBestOffer = (hippo, rxSence, careCard, rxLess, type) => {
    const prices = [hippo, rxSence, careCard, rxLess].filter(price => price !== undefined && price !== null);

    if (prices.length === 0) {
      return;
    }

    const lowestPrice = Math.min(...prices);

    if (type && type == "comparison") return lowestPrice;
    return lowestPrice.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const getCrxBestPrice = (sub_element) => {
    let priceToAdd = 0;
    let priceToMinus = 0;
    sub_element?.drugs?.map((dr) => {
      if (dr?.selectedDrug?.connectiveRxOffer?.hasCard == 1) {
        priceToAdd += parseFloat(dr?.selectedDrug?.connectiveRxOffer?.price);
        priceToMinus += dr.bestPrice;
      }
    })
    let newSum = sub_element.bestPrice - priceToMinus + priceToAdd;
    return newSum.toLocaleString(
      undefined,
      {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
  }
  const getAriaLabel = (obj) => {
    let labelString = "Offer information " + "from $" + obj?.estRetailPrice?.toLocaleString(
      undefined,
      {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }
    ) + " retail price";
    if ((obj?.patient_pay >= 0 && obj?.patient_pay / 100) >= selectBestOffer(obj?.hippoPrice, obj?.rxSensePrice, obj?.careCardPrice, obj?.rxLessPrice, "comparison")) {
      labelString = labelString + " $" + (obj?.patient_pay / 100)?.toLocaleString(
        undefined,
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      ) + " with insurance";
    }
    if (obj?.description == "with insurance") {
      labelString = labelString + " $" + selectBestOffer(obj?.hippoPrice, obj?.rxSensePrice, obj?.careCardPrice, obj?.rxLessPrice) + " with RxLink offer"
    }
    if (obj?.patient_pay >= 0 && obj?.patient_pay / 100 < selectBestOffer(obj?.hippoPrice, obj?.rxSensePrice, obj?.careCardPrice, obj?.rxLessPrice, "comparison")) {
      labelString = labelString + " $" + (obj?.patient_pay / 100)?.toLocaleString(
        undefined,
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      ) + " with insurance"
    }

    return labelString;
  }

  function processInput(input) {
    if (typeof input === 'string' && input.startsWith('[') && input.endsWith(']')) {
      // Remove the brackets
      let trimmedInput = input.slice(1, -1);

      // Split the string by comma and trim each element
      let arrayOfStrings = trimmedInput.split(',').map(item => item.trim());

      return arrayOfStrings;
    } else {
      // Assume the input is already in the correct format
      return input;
    }
  }

  const pharmacyState = JSON.parse(sessionStorage.getItem("selectMapValues"));

  return (
    <React.Fragment>
      {loading ? (
        <LoadingComponent />
      ) : (
        <motion.section>
          <div className="">
            <div className="">
              <div className="">
                <form id="loginID">
                  <div className="row" id="cc-info-div">
                    <AnimatePresence>
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <InfiniteScroll
                          dataLength={medicationInfo?.length}
                          next={() => {
                            fetchMoreMedicines();
                          }}
                          hasMore={!addingMedicine && medicationInfo?.length < fullMedicinesList?.length}
                          loader={<div style={{ textAlign: "center", zIndex: 1001 }}><Spinner animation="border" variant="primary" /></div>}
                        >
                          {medicationInfo?.map((obj, index) => {

                            let names = processInput(obj?.selectedDrug?.names);
                            console.log({ medDetails: obj })
                            return (
                              <motion.div
                                className="swipe-list"
                                key={`${obj?.selectedDrug?.drugSeqNo}`}
                                // layout
                                initial={{
                                  y: -100,
                                  x: 0,
                                  opacity: 0,
                                }}
                                animate={{
                                  y: 0,
                                  x: 0,
                                  opacity: 1,
                                }}
                                exit={{
                                  opacity: 1,
                                  x: 200,
                                  // transition: { duration: 1 },
                                }}
                                transition={{ type: "tween" }}
                                whileHover={{
                                  scale: 1.02,
                                  transition: { duration: 0.2 },
                                }}
                                style={{ position: "relative", zIndex: 100, marginInline: 12 }}
                              >
                                <div className="item-group">
                                  <div className="row">
                                    <div
                                      className={
                                        deleteShowParam
                                          ? "col-10 col-sm-10 col-md-10 col-lg-10"
                                          : "col-12 col-sm-12 col-md-12 col-lg-12"
                                      }
                                    >
                                      <div
                                        className="medications_inner"
                                      >
                                        <div className="row"
                                          style={(obj?.selectedDrug?.manufacturerOffer && obj?.selectedDrug?.type == "Brand" && userDetail?.hasInsurance) || ((!obj?.selectedDrug?.manufacturerOffer || obj?.selectedDrug.type == "Generic" && userDetail?.hasInsurance) && obj?.costPlusDrugPrice >
                                            0 && !(obj?.estRetailPrice < obj?.costPlusDrugPrice)) || (obj?.selectedDrug?.connectiveRxOffer && obj?.selectedDrug?.connectiveRxOffer?.hasCard == "0" && userDetail?.hasInsurance) ? { borderBottom: "1px solid rgba(20, 48, 82, 0.08)", borderRadius: 8, marginInline: -16, boxShadow: "0px 3px 0px 0px rgba(20, 48, 82, 0.04)", paddingBottom: 6 } : {}}>
                                          <div className="col-12 col-sm-12 col-md-6 col-lg-7 autoMargin desktop-only"></div>
                                          <div className="col-12 col-sm-12 col-md-6 col-lg-5 autoMargin desktop-only">
                                            <div className="row">
                                              <div className="col-12 col-sm-12 col-md-10 col-lg-10 autoMargin">
                                                <div className="row">
                                                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                    <p style={{ fontSize: "10px", fontStyle: "italic", textAlign: "center", marginTop: -6, marginBottom: 2 }}>Click a box below to change quantity, strength or dosage form</p>
                                                  </div>

                                                </div>
                                              </div>
                                              <div className="col-12 col-sm-12 col-md-2 col-lg-2 autoMargin webView" style={{ display: "flex", justifyContent: "end" }}>

                                              </div>

                                            </div>
                                          </div>
                                          <div className="col-12 col-sm-12 col-md-6 col-lg-7 autoMargin">
                                            <div className="row">
                                              {/* autoMargin */}
                                              <div
                                                className={
                                                  deleteShowParam
                                                    ? "col-12 col-sm-6 col-md-6 col-lg-6 "
                                                    : "col-6 col-sm-6 col-md-6 col-lg-7"
                                                }
                                                style={{ paddingLeft: "3px", paddingRight: 2 }}
                                              >
                                                <div className="row">
                                                  <div className="col-4 col-sm-4 col-md-5 col-lg-3 autoMargin">
                                                    <div className="">
                                                      {obj?.selectedDrug?.form ==
                                                        "Tablet" && (
                                                          <div className="text-center">
                                                            <img
                                                              src={Tablet}
                                                              className="img-fluid "
                                                              alt=""
                                                            />
                                                          </div>
                                                        )}
                                                      {obj?.selectedDrug?.form !=
                                                        "Tablet" && (
                                                          <div className="text-center">
                                                            <img
                                                              src={Liquid}
                                                              className="img-fluid "
                                                              alt=""
                                                            />
                                                          </div>
                                                        )}
                                                    </div>
                                                  </div>
                                                  <div className="col-8 col-sm-8 col-md-7 col-lg-9 p-0 autoMargin ">
                                                    <div className="">
                                                      <span
                                                        style={{ fontSize: 10 }}
                                                        className="text"
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title={
                                                          obj?.selectedDrug
                                                            ?.type === "Generic"
                                                            ? obj?.selectedDrug
                                                              ?.name
                                                            :
                                                            names[0] ===
                                                              obj?.selectedDrug
                                                                ?.name
                                                              ? names[1]
                                                              : names[0]
                                                        }
                                                      >
                                                        {obj?.selectedDrug
                                                          ?.type === "Generic"
                                                          ? obj?.selectedDrug
                                                            ?.name
                                                          :
                                                          names[0] ===
                                                            obj?.selectedDrug
                                                              ?.name
                                                            ? names[1]
                                                            : names[0]}
                                                      </span>
                                                      <br></br>
                                                      <span
                                                        className="text"
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        style={{ fontSize: 16, fontWeight: 600 }}
                                                        title={
                                                          obj?.selectedDrug
                                                            ?.type === "Generic"
                                                            ? names[0] ===
                                                              obj?.selectedDrug
                                                                ?.name
                                                              ? names[1]
                                                              :
                                                              names[0]
                                                            : obj?.selectedDrug
                                                              ?.name
                                                        }
                                                      >
                                                        {obj?.selectedDrug
                                                          ?.type === "Generic"
                                                          ? names[0] ===
                                                            obj?.selectedDrug
                                                              ?.name
                                                            ? names[1]
                                                            : names[0]
                                                          : obj?.selectedDrug
                                                            ?.name}
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                className={
                                                  deleteShowParam
                                                    ? "col-12 col-sm-6 col-md-6 col-lg-6 autoMargin amountFountSize"
                                                    : "col-6 col-sm-6 col-md-6 col-lg-5 autoMargin pl-0"
                                                }
                                              >
                                                <div className="medications_inner_right">
                                                  <div className="dlr-Sup ">
                                                    {(obj?.chain || obj?.bestPrice > 0 || obj?.hasInsurance) ? (
                                                      <React.Fragment>
                                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                          <p style={{ fontSize: 11, lineHeight: 1.3, marginRight: 4 }}>
                                                            {obj?.description == "with insurance" ? "With Insurance" : "With RxLink Offer"}
                                                          </p>
                                                          {!(obj?.estRetailPrice == 0 || obj?.estRetailPrice <= obj.bestPrice || obj?.estRetailPrice == obj.bestPrice) && (
                                                            <React.Fragment>
                                                              <OverlayTrigger
                                                                placement="auto"
                                                                overlay={
                                                                  <Tooltip id={`tooltip-${index}`}>
                                                                    <p >
                                                                      {!(obj?.estRetailPrice == 0 || obj?.estRetailPrice <= obj.bestPrice || obj?.estRetailPrice == obj.bestPrice) &&
                                                                        <React.Fragment>
                                                                          from <br />
                                                                          <span style={{ fontWeight: "bold" }}>${obj?.estRetailPrice?.toLocaleString(
                                                                            undefined,
                                                                            {
                                                                              minimumFractionDigits: 2,
                                                                              maximumFractionDigits: 2,
                                                                            }
                                                                          )}</span>
                                                                          <span> retail price</span>
                                                                        </React.Fragment>}
                                                                      {(obj?.description == "with insurance" && obj?.bestPrice >= 0) >= selectBestOffer(obj?.hippoPrice, obj?.rxSensePrice, obj?.careCardPrice, obj?.rxLessPrice, "comparison") && <React.Fragment><br />
                                                                        <span style={{ fontWeight: "bold" }}>${(obj?.bestPrice)?.toLocaleString(
                                                                          undefined,
                                                                          {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2,
                                                                          }
                                                                        )}</span>
                                                                        <span> with insurance</span> </React.Fragment>}
                                                                      {obj?.description == "with insurance" && <React.Fragment><br />
                                                                        <span style={{ fontWeight: "bold" }}>${selectBestOffer(obj?.hippoPrice, obj?.rxSensePrice, obj?.careCardPrice, obj?.rxLessPrice)}</span>
                                                                        <span> with RxLink offer</span> </React.Fragment>}
                                                                      {obj?.description == "with insurance" && obj?.bestPrice >= 0 < selectBestOffer(obj?.hippoPrice, obj?.rxSensePrice, obj?.careCardPrice, obj?.rxLessPrice, "comparison") && <React.Fragment><br />
                                                                        <span style={{ fontWeight: "bold" }}>${(obj?.bestPrice)?.toLocaleString(
                                                                          undefined,
                                                                          {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2,
                                                                          }
                                                                        )}</span>
                                                                        <span> with insurance</span> </React.Fragment>}
                                                                      {(obj?.description != "with insurance") && obj.prismaDrugPrice != null && obj.prismaDrugPrice != undefined && <React.Fragment><br />
                                                                        <span style={{ fontWeight: "bold" }}>${parseFloat(obj?.prismaDrugPrice)?.toLocaleString(
                                                                          undefined,
                                                                          {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2,
                                                                          }
                                                                        )}</span>
                                                                        <span> with insurance</span> </React.Fragment>}

                                                                        {(obj?.description != "with insurance") && parseFloat(obj?.patient_pay) > 0  && <React.Fragment><br />
                                                                        <span style={{ fontWeight: "bold" }}>${parseFloat(obj.patient_pay/ 100)?.toLocaleString(
                                                                          undefined,
                                                                          {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2,
                                                                          }
                                                                        )}</span>
                                                                        <span> with insurance</span> </React.Fragment>}
                                                                    </p>
                                                                  </Tooltip>
                                                                }
                                                              >
                                                                {/* <a href="javascript:void(0)"> */}
                                                                <img tabIndex={0} src={InfoSvg} style={{ height: 14, width: 14, marginTop: -6, cursor: "pointer" }}
                                                                  aria-label={getAriaLabel(obj)}
                                                                  alt="Offer Information"
                                                                />
                                                                {/* </a> */}
                                                              </OverlayTrigger>
                                                            </React.Fragment>
                                                          )}

                                                        </div>
                                                        {(obj?.estRetailPrice == 0 || obj?.estRetailPrice <= obj.bestPrice || obj?.estRetailPrice == obj.bestPrice) ? (<React.Fragment>

                                                        </React.Fragment>) :
                                                          <React.Fragment>
                                                            {obj?.estRetailPrice > 0 && (
                                                              <React.Fragment>
                                                                <div className="medFlex">
                                                                  <span className="clr_a2a4a5 mb-0 padding3 strikeThroughText strikeThrough" tabIndex={0}
                                                                    style={{ fontSize: 11, marginRight: 5 }}
                                                                    aria-label={"retail price $" + obj?.estRetailPrice?.toLocaleString(
                                                                      undefined,
                                                                      {
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2,
                                                                      }
                                                                    )}
                                                                  >
                                                                    ${obj?.estRetailPrice?.toLocaleString(
                                                                      undefined,
                                                                      {
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2,
                                                                      }
                                                                    )}
                                                                  </span>

                                                                </div>
                                                              </React.Fragment>
                                                            )}
                                                          </React.Fragment>}

                                                        <div className="medFlex">
                                                          {obj?.selectedDrug?.connectiveRxOffer?.hasCard != 1 &&
                                                            <span className="webView"
                                                              style={{ fontWeight: "bold" }}
                                                              aria-label={
                                                                "price with RxLink offer $" +
                                                                (obj?.selectedDrug?.connectiveRxOffer?.hasCard !== 1
                                                                  ? (obj?.bestPrice?.toLocaleString(undefined, {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                  }) || "N/A")
                                                                  : (obj?.bestPrice?.toLocaleString(undefined, {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                  }) || "N/A")
                                                                )
                                                              }
                                                              tabIndex={0}
                                                            >
                                                              ${
                                                                obj?.bestPrice?.toLocaleString(
                                                                  undefined,
                                                                  {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                  }
                                                                ) ||
                                                                "N/A"}
                                                            </span>}
                                                          {obj?.selectedDrug?.connectiveRxOffer?.hasCard != 1 && <span className="mobileView"
                                                            style={{ fontWeight: "bold" }}
                                                            aria-label={
                                                              "price with RxLink offer $" +
                                                              (obj?.selectedDrug?.connectiveRxOffer?.hasCard !== 1
                                                                ? (obj?.bestPrice?.toLocaleString(undefined, {
                                                                  minimumFractionDigits: 2,
                                                                  maximumFractionDigits: 2,
                                                                }) || "N/A")
                                                                : (obj?.bestPrice?.toLocaleString(undefined, {
                                                                  minimumFractionDigits: 2,
                                                                  maximumFractionDigits: 2,
                                                                }) || "N/A")
                                                              )
                                                            }
                                                            tabIndex={0}
                                                          >
                                                            ${obj?.bestPrice?.toLocaleString(
                                                              undefined,
                                                              {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2,
                                                              }
                                                            ) ||
                                                              "N/A"}
                                                          </span>}
                                                          {obj?.selectedDrug?.connectiveRxOffer?.hasCard == 1 &&
                                                            <span className="webView"
                                                              style={{ fontWeight: "bold" }}
                                                              aria-label={"price with RxLink offer $ " + parseFloat(obj?.selectedDrug?.connectiveRxOffer?.price)?.toLocaleString(
                                                                undefined,
                                                                {
                                                                  minimumFractionDigits: 2,
                                                                  maximumFractionDigits: 2,
                                                                }
                                                              )}
                                                              tabIndex={0}
                                                            >
                                                              ${parseFloat(obj?.selectedDrug?.connectiveRxOffer?.price)?.toLocaleString(
                                                                undefined,
                                                                {
                                                                  minimumFractionDigits: 2,
                                                                  maximumFractionDigits: 2,
                                                                }
                                                              ) ||
                                                                "N/A"}
                                                            </span>}
                                                          {obj?.selectedDrug?.connectiveRxOffer?.hasCard == 1 && <span className="mobileView"
                                                            style={{ fontWeight: "bold" }}
                                                            tabIndex={0}
                                                            aria-label={"price with RxLink offer $ " + parseFloat(obj?.selectedDrug?.connectiveRxOffer?.price)?.toLocaleString(
                                                              undefined,
                                                              {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2,
                                                              }
                                                            )}                                                          >
                                                            ${parseFloat(obj?.selectedDrug?.connectiveRxOffer?.price)?.toLocaleString(
                                                              undefined,
                                                              {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2,
                                                              }
                                                            ) ||
                                                              "N/A"}
                                                          </span>}
                                                        </div>

                                                      </React.Fragment>) : (<React.Fragment>Not Available</React.Fragment>)}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-12 col-sm-12 col-md-6 col-lg-5 autoMargin ">
                                            <div className="row">
                                              <div className="col-12 col-sm-12 col-md-10 col-lg-10 autoMargin">
                                                <div className="row">
                                                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                    <p style={{ fontSize: "10px", fontStyle: "italic", textAlign: "center" }} className="mobile-only">
                                                      Click a box below to change quantity, strength or dosage form
                                                    </p>

                                                    <a href="javascript:void(0)" onClick={() => {
                                                      setIsVisibleEditModal(true);
                                                      setSelectedDrug(obj.selectedDrug);
                                                    }}
                                                      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                                                      className="text-center m-0 pointer medications_inner peiceUpdate" aria-label={obj?.selectedDosage + obj?.selectedQty + obj?.selectedForm + " Edit medication"}>
                                                      {obj?.selectedDosage}
                                                      {obj?.selectedDosage &&
                                                        ","}{" "}
                                                      {obj?.selectedQty}{" "}
                                                      {obj?.selectedForm}
                                                    </a>
                                                  </div>
                                                  {/* LOGO CODE HERE */}
                                                </div>
                                              </div>
                                              <div className="col-12 col-sm-12 col-md-2 col-lg-2 autoMargin webView" style={{ display: "flex", justifyContent: "end" }}>
                                                <a href="javascript:void(0)" onClick={() =>
                                                  removeMedication(
                                                    medicationInfo?.length -
                                                    1 -
                                                    index,
                                                    obj?.selectedDrug
                                                      ?.drugSeqNo
                                                  )
                                                }
                                                  style={{ cursor: "auto", width: 21 }}
                                                >
                                                  <div className="text-end">
                                                    <img
                                                      src={trash}
                                                      className=" pointer"
                                                      alt="Delete"

                                                    ></img>
                                                  </div>
                                                </a>
                                              </div>

                                            </div>
                                          </div>
                                        </div>
                                        {obj?.selectedDrug?.manufacturerOffer && obj?.selectedDrug?.type == "Brand" && userDetail?.hasInsurance &&
                                          <div className="row" style={{ position: "relative", top: 8 }}>
                                            <div
                                              className="col-12"
                                              style={{ fontSize: 11, fontWeight: 400, fontFamily: "inherit" }}
                                            >
                                              {obj?.selectedDrug?.manufacturerOffer?.offerDescription + " "}

                                              <a
                                                href={obj?.selectedDrug?.manufacturerOffer.offerLink}
                                                target="_blank"
                                                style={{ fontSize: 11, paddingLeft: 4 }}
                                                onClick={(e) => {
                                                  e.preventDefault();

                                                  let drugSeqNo = obj?.selectedDrug?.drugSeqNo;
                                                  if (openedTabs[drugSeqNo] && !openedTabs[drugSeqNo].closed) {
                                                    openedTabs[drugSeqNo].focus();
                                                  }
                                                  else {
                                                    let reference = window.open(obj?.selectedDrug?.manufacturerOffer.offerLink, "_blank");
                                                    setOpenedTabs(prevState => ({ ...prevState, [drugSeqNo]: reference }));

                                                    httpClientConnective.post("manufactureTracking", {
                                                      appUserSeqNo: userDetail?.appUserSeqNo,
                                                      fromUi: 1,
                                                      crxBrandSeqNo: obj.selectedDrug.manufacturerOffer.crxBrandSeqNo,
                                                      drugSeqNo: obj.selectedDrug.drugSeqNo,
                                                      pharmacyNpi: valParam.npi
                                                    })
                                                      .then((res) => {
                                                      }).catch((err) => {
                                                        console.log({ err })
                                                      })
                                                  }
                                                }}
                                              >
                                                Learn More
                                              </a>
                                            </div>
                                          </div>
                                        }
                                        {(!obj?.selectedDrug?.manufacturerOffer || obj?.selectedDrug.type == "Generic") && obj?.costPlusDrugPrice >
                                          0 && !(obj?.estRetailPrice < obj?.costPlusDrugPrice) &&
                                          <div className="row" style={{ position: "relative", top: 8 }}>
                                            <div
                                              className="col-12"
                                              style={{ fontSize: 11, fontWeight: 400, fontFamily: "inherit", display: "inline-flex", alignItems: "center" }}
                                            >
                                              <img
                                                src={CPDImg}
                                                className={"CPDIMG"}
                                                alt="Cost Plus Drugs"
                                              ></img>
                                              {!isMobile && <div style={{ display: "inline-block", fontSize: 11, paddingLeft: isMobile ? "0.6rem" : "1.2rem", float: isMobile ? "right" : "none" }}>
                                                {!(obj?.estRetailPrice <= obj?.costPlusDrugPrice) && <span style={{ color: "rgba(0,0,0,0.35)", }} className="strikeThrough">
                                                  ${obj?.estRetailPrice?.toLocaleString(
                                                    undefined,
                                                    {
                                                      minimumFractionDigits: 2,
                                                      maximumFractionDigits: 2,
                                                    }
                                                  )}
                                                </span>}
                                                <span style={{ paddingLeft: 4, fontWeight: 600, color: "rgba(0,0,0,0.7)" }}>
                                                  ${obj?.costPlusDrugPrice?.toLocaleString(
                                                    undefined,
                                                    {
                                                      minimumFractionDigits: 2,
                                                      maximumFractionDigits: 2,
                                                    }
                                                  ) ||
                                                    "N/A"}
                                                </span>
                                              </div>}
                                              <div style={{ display: "inline-block", paddingLeft: isMobile ? "0.6rem" : "1.2rem" }}>
                                                <OverlayTrigger
                                                  delay={{ hide: 100, show: 100 }}
                                                  trigger="hover"
                                                  ref={tooltipRef}
                                                  overlay={(props) => (
                                                    <Popover {...props}>

                                                      <div style={{ padding: 8, textAlign: "center" }}>Click here to sign up for Mark Cuban CostPlus Drug Company</div>
                                                    </Popover>
                                                  )}
                                                  placement="auto"
                                                >
                                                  <NewTabRedirector
                                                    type="cpd"
                                                    apiEndpoint={(payload) => httpClientConnective.post("costPlusDrugTracking", payload)}
                                                    apiPayload={{
                                                      appUserSeqNo: userDetail?.appUserSeqNo,
                                                      ndc: obj.selectedDrug.ndc,
                                                      rxLinkBestPrice: "$" + obj.bestPrice,
                                                      preferredPharmacyPrice: "$" + obj.bestPrice,
                                                      costPlusDrugPrice: "$" + obj.costPlusDrugPrice
                                                    }}
                                                    tabKey={obj?.selectedDrug?.drugSeqNo}
                                                    label="Join and save"
                                                    style={{ fontSize: 11 }}
                                                    loadingMessage="Please wait while we redirect you to CostPlus Drug..."
                                                    errorMessage="Something went wrong while loading CostPlus Drug!"
                                                    onError={(err) => console.log("Failed to redirect:", err)}
                                                  />
                                                </OverlayTrigger>
                                              </div>
                                              {isMobile && <div style={{ display: "inline-block", fontSize: 11, paddingLeft: "0.5rem", paddingRight: "0.5rem", float: isMobile ? "right" : "none" }}>
                                                {!(obj?.estRetailPrice <= obj?.costPlusDrugPrice) && <span style={{ color: "rgba(0,0,0,0.35)", }} className="strikeThrough">${obj?.estRetailPrice?.toLocaleString(
                                                  undefined,
                                                  {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                  }
                                                )}</span>}
                                                <span style={{ paddingLeft: 4, fontWeight: 600, color: "rgba(0,0,0,0.7)" }}>${obj?.costPlusDrugPrice?.toLocaleString(
                                                  undefined,
                                                  {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                  }
                                                ) ||
                                                  "N/A"}</span>
                                              </div>}
                                            </div>
                                          </div>
                                        }

                                        {(obj?.selectedDrug?.connectiveRxOffer && obj?.selectedDrug?.connectiveRxOffer?.hasCard == "0" && obj?.selectedDrug?.connectiveRxOffer?.hasImageUrl == "1" && userDetail?.hasInsurance) &&
                                          <div className="row" style={{ position: "relative", top: 8 }}>
                                            <div
                                              className="col-12"
                                              style={{ fontSize: 11, fontWeight: 400, fontFamily: "inherit", display: "inline-flex", alignItems: "center" }}
                                            >
                                              <LogoComponent name={obj?.selectedDrug?.search_name?.toLowerCase()} />
                                              {true && <div style={{ display: "inline-block", fontSize: 11, paddingLeft: 18 }}>
                                                <span style={{ fontWeight: 500, color: "rgba(0,0,0,0.7)" }}>
                                                  {obj?.selectedDrug?.connectiveRxOffer?.offerDesc + " "}
                                                  <NewTabRedirector
                                                    type="connective"
                                                    apiEndpoint={(payload) => httpClientConnective.post("generateConnectiveRx", payload)}
                                                    apiPayload={{
                                                      appUserSeqNo: userDetail?.appUserSeqNo,
                                                      connectiveRxRequest: [
                                                        {
                                                          appUserSeqNo: userDetail?.appUserSeqNo,
                                                          drugNdc: obj?.selectedDrug?.connectiveRxOffer?.drugNdc,
                                                          crxNetPriceSubSeqNo: obj?.selectedDrug?.connectiveRxOffer?.crxNetPriceSubSeqNo,
                                                          dob: userDetail?.dob,
                                                          gender: userDetail?.gender,
                                                          patientSeqNo: userDetail?.patientSeqNo,
                                                          pharmacySeqNo: pharmacyState?.pharmacySeqNo || "1213",
                                                          pharmacyState:
                                                            pharmacyState?.state || ownPosition?.responseZIP?.region_code || "",
                                                          zipcode: pharmacyState?.zipCode || ownPosition?.postalCode || "",
                                                          campaignEndDate: obj?.selectedDrug?.connectiveRxOffer?.campaignEndDate,
                                                          partner: obj?.selectedDrug?.connectiveRxOffer?.partner,
                                                          npi: userDetail?.npi
                                                        },
                                                      ]
                                                    }}
                                                    tabKey={obj?.selectedDrug?.drugSeqNo}
                                                    label="Click Here"
                                                    style={{ fontSize: 11 }}
                                                    loadingMessage="Please wait while we redirect you to saving offer..."
                                                    errorMessage="Something went wrong while loading saving offer!"
                                                    onError={(err) => console.log("Failed to redirect:", err)}
                                                  />
                                                </span>
                                              </div>}
                                            </div>
                                          </div>
                                        }
                                        <div className="row">
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className={
                                        deleteShowParam
                                          ? "col-2 col-sm-2 col-md-2 col-lg-2 marginAuto"
                                          : "col-12 col-sm-12 col-md-12 col-lg-12"
                                      }
                                    >
                                      {deleteShowParam && (
                                        <a href="javascript:void(0)" style={{ cursor: "none" }} onClick={() =>
                                          removeMedication(
                                            medications?.length - 1 - index,
                                            obj?.selectedDrug?.drugSeqNo
                                          )
                                        }>
                                          <div className="box-action">
                                            <i class="fa-regular fa-trash-can pointer" alt="Delete"></i>
                                          </div>
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </motion.div>
                            );
                          })}
                        </InfiniteScroll>
                        {medicationInfo.length > 0 && <div style={{ position: "relative", top: 6 }}>
                          <p style={{ fontSize: 13 }}>
                            Savings based on all data currently available to RxLink, including your insurance and co-pay. Always compare the RxLink discounted price to your insurance co-pay; your price with
                            insurance should never be more than the RxLink price, but may be lower.
                          </p>
                        </div>}
                        {
                          disclaimer.length > 0 &&
                          <div style={{ position: "relative", top: -6 }}>
                            <p style={{ fontSize: 13 }}>
                              {/* {disclaimer?.[0]} */}
                              <StringToHTML htmlString={disclaimer?.[0]} />
                            </p>
                          </div>
                        }
                        {viewSavingOptions && (
                          <div
                            className=""
                            id="listingContent"
                            initial={{ y: -300 }}
                            animate={{ y: 0 }}
                            transition={{ type: "tween", duration: 2.2 }}
                          >
                            <div className="mb-4">
                              <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                  <div className="price_comparison_home_4">
                                    <AnimatePresence>
                                      <div className="border-top">
                                        {bestPackage?.map(
                                          (sub_element) => {
                                            let name = normalizeCompanyName(sub_element.pharmacyName);
                                            let type = searchPharmacyType(name.toLowerCase());

                                            if (sub_element.pharmacyName != "local") {
                                              return (
                                                <motion.div
                                                  className="row"
                                                  key={sub_element.pharmacyName}
                                                  layout
                                                  initial={{
                                                    y: -150,
                                                    // x: 0,
                                                    opacity: 0,
                                                    // scale: 0.8
                                                  }}
                                                  animate={{
                                                    y: 0,
                                                    // x: 0,
                                                    opacity: 1,
                                                    // scale:1
                                                  }}
                                                  exit={{
                                                    opacity: 0,
                                                  }}
                                                  transition={{
                                                    type: "tween",
                                                    delay: 0.3,
                                                  }}
                                                  style={{ alignItems: "center" }}
                                                >
                                                  {sub_element?.chain !==
                                                    "cost_plus_drug" && (
                                                      <React.Fragment>
                                                        <div className="col-12 col-sm-4 col-md-4 col-lg-4 flex justify-content">
                                                          <div className="small_medication_logo img-size">
                                                            <img
                                                              src={
                                                                searchPharmacyLogo(sub_element?.pharmacyName, sub_element?.logo
                                                                  ?.small, sub_element?.chain.toLowerCase())
                                                              }
                                                              // className="img-fluid"
                                                              style={{ width: 22, height: 22 }}
                                                              alt={sub_element?.pharmacyName + " logo"}
                                                            />
                                                          </div>
                                                          <span style={{ fontSize: 18, display: "flex", alignItems: "center", textAlign: "left" }}>
                                                            {
                                                              sub_element?.pharmacyName
                                                            }
                                                          </span>
                                                        </div>

                                                        <div className="col-6 col-sm-4 col-md-3 col-lg-3 est-retail-price">
                                                          {(sub_element?.estRetailPrice == 0 || sub_element?.estRetailPrice <= sub_element?.bestPrice || sub_element?.estRetailPrice == sub_element?.bestPrice) ? (<React.Fragment>

                                                          </React.Fragment>)
                                                            : <React.Fragment>
                                                              {sub_element?.estRetailPrice > 0 ? (
                                                                <React.Fragment>
                                                                  <div className="text-center">
                                                                    <p>
                                                                      <sup>$&nbsp;</sup>
                                                                      <s>
                                                                        {sub_element?.estRetailPrice?.toLocaleString(
                                                                          undefined,
                                                                          {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2,
                                                                          }
                                                                        )}
                                                                      </s>
                                                                    </p>
                                                                    <span>
                                                                      est. cash price
                                                                    </span>
                                                                  </div>
                                                                </React.Fragment>
                                                              ) : (
                                                                <React.Fragment>
                                                                  <h5 className="text-center fw-bold">
                                                                    N/A
                                                                  </h5>
                                                                </React.Fragment>
                                                              )}
                                                            </React.Fragment>}

                                                        </div>


                                                        <div className="col-6 col-sm-4 col-md-3 col-lg-3">
                                                          <div className="text-center">
                                                            <p>
                                                              <sup>$&nbsp;</sup>

                                                              {getCrxBestPrice(sub_element)
                                                              }
                                                            </p>
                                                            <span>
                                                              {(sub_element?.description == "with discount card" || sub_element?.description == "with Single Care") ? "with RxLink Offer" : sub_element?.description}
                                                              {/* with RxLink discount */}
                                                            </span>
                                                          </div>
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-2 col-lg-2">
                                                          {/* <MotionButton> */}
                                                          <button
                                                            aria-label={'Select pharmacy ' + sub_element?.pharmacyName}
                                                            className="btn_success"
                                                            onClick={() =>
                                                              selectMap(sub_element)
                                                            }
                                                          >
                                                            Select
                                                          </button>
                                                          {/* </MotionButton> */}
                                                        </div>

                                                        <hr className="m-0"></hr>
                                                      </React.Fragment>
                                                    )}
                                                </motion.div>
                                              )
                                            }
                                            if (sub_element.pharmacyName == "local" && sub_element?.pharmaciesList?.length > 0) {
                                              return (
                                                <React.Fragment>
                                                  <motion.div
                                                    className="row"
                                                    key={sub_element.pharmacyName}
                                                    layout
                                                    initial={{
                                                      y: -150,
                                                      // x: 0,
                                                      opacity: 0,
                                                      // scale: 0.8
                                                    }}
                                                    animate={{
                                                      y: 0,
                                                      // x: 0,
                                                      opacity: 1,
                                                      // scale:1
                                                    }}
                                                    exit={{
                                                      opacity: 0,
                                                    }}
                                                    transition={{
                                                      type: "tween",
                                                      delay: 0.3,
                                                    }}
                                                    style={{ alignItems: 'center' }}
                                                  >
                                                    {sub_element?.chain !==
                                                      "cost_plus_drug" && (
                                                        <React.Fragment>
                                                          <div className="col-12 col-sm-4 col-md-4 col-lg-4 flex justify-content">
                                                            <div className="small_medication_logo img-size">
                                                              <img
                                                                src={
                                                                  searchPharmacyLogo(sub_element?.pharmacyName, "", sub_element?.chain.toLowerCase())
                                                                }
                                                                // className="img-fluid"
                                                                style={{ width: 22, height: 22 }}
                                                                alt={sub_element?.pharmacyName + " logo"}
                                                              />
                                                            </div>
                                                            <span style={{ fontSize: 18, display: "flex", alignItems: "center", textAlign: "left" }}>
                                                              Local Pharmacies
                                                            </span>
                                                          </div>

                                                          <div className="col-6 col-sm-4 col-md-3 col-lg-3 est-retail-price">
                                                            {(sub_element?.estRetailPrice == 0 || sub_element?.estRetailPrice <= sub_element?.bestPrice || sub_element?.estRetailPrice == sub_element?.bestPrice) ? (<React.Fragment>

                                                            </React.Fragment>)
                                                              : <React.Fragment>
                                                                {sub_element?.estRetailPrice > 0 ? (
                                                                  <React.Fragment>
                                                                    <div className="text-center">
                                                                      <p>
                                                                        <sup>$&nbsp;</sup>
                                                                        <s>
                                                                          {sub_element?.estRetailPrice?.toLocaleString(
                                                                            undefined,
                                                                            {
                                                                              minimumFractionDigits: 2,
                                                                              maximumFractionDigits: 2,
                                                                            }
                                                                          )}
                                                                        </s>
                                                                      </p>
                                                                      <span>
                                                                        est. cash price
                                                                      </span>
                                                                    </div>
                                                                  </React.Fragment>
                                                                ) : (
                                                                  <React.Fragment>
                                                                    <h5 className="text-center fw-bold">
                                                                      N/A
                                                                    </h5>
                                                                  </React.Fragment>
                                                                )}
                                                              </React.Fragment>}
                                                          </div>

                                                          <div className="col-6 col-sm-4 col-md-3 col-lg-3">

                                                            <div className="text-center">
                                                              <span>As low as</span>
                                                              <p>
                                                                <sup>$&nbsp;</sup>
                                                                {getCrxBestPrice(sub_element)
                                                                }
                                                              </p>
                                                              <span>
                                                                {(sub_element?.description == "with discount card" || sub_element?.description == "with Single Care") ? "with RxLink Offer" : sub_element?.description}
                                                              </span>
                                                            </div>
                                                          </div>
                                                          <div className="col-12 col-sm-12 col-md-2 col-lg-2">
                                                            <button
                                                              aria-label={'Select pharmacy ' + sub_element?.pharmacyName}
                                                              className="btn_success"
                                                              onClick={(e) => {
                                                                e.preventDefault();
                                                                setShowLocalPharmacies((prev) => !prev);
                                                              }
                                                              }
                                                            >
                                                              {showLocalPharmacies ? "Hide" : "Show"}
                                                            </button>
                                                          </div>
                                                          <hr className="m-0"></hr>
                                                        </React.Fragment>
                                                      )}
                                                  </motion.div>
                                                  {
                                                    showLocalPharmacies && sub_element.pharmaciesList.map((pharmacy) => {
                                                      return (
                                                        <motion.div
                                                          className="row"
                                                          style={{ marginLeft: 6 }}
                                                          key={pharmacy}
                                                          id="localPharmaciesList"
                                                          layout
                                                          initial={{
                                                            y: -150,
                                                            // x: 0,
                                                            opacity: 0,
                                                            // scale: 0.8
                                                          }}
                                                          animate={{
                                                            y: 0,
                                                            // x: 0,
                                                            opacity: 1,
                                                            // scale:1
                                                          }}
                                                          exit={{
                                                            opacity: 0,
                                                          }}
                                                          transition={{
                                                            type: "tween",
                                                            delay: 0.3,
                                                          }}
                                                        >
                                                          {pharmacy?.chain !==
                                                            "cost_plus_drug" && (
                                                              <React.Fragment>
                                                                <div className="col-12 col-sm-4 col-md-4 col-lg-4 flex justify-content">
                                                                  <div className="small_medication_logo img-size">
                                                                    <img
                                                                      src={
                                                                        searchPharmacyLogo(pharmacy?.pharmacyName, "", pharmacy?.chain.toLowerCase())
                                                                      }
                                                                      // className="img-fluid"
                                                                      style={{ width: 22, height: 22 }}
                                                                      alt={pharmacy?.pharmacyName + " logo"}
                                                                    />
                                                                  </div>
                                                                  <span style={{ fontSize: 18, display: "flex", alignItems: "center", textAlign: "left" }}>
                                                                    {
                                                                      pharmacy?.pharmacyName
                                                                    }
                                                                  </span>
                                                                </div>

                                                                <div className="col-6 col-sm-4 col-md-3 col-lg-3 est-retail-price">
                                                                  {(pharmacy?.estRetailPrice == 0 || pharmacy?.estRetailPrice <= pharmacy?.bestPrice || pharmacy?.estRetailPrice == pharmacy?.bestPrice) ? (<React.Fragment>

                                                                  </React.Fragment>)
                                                                    : <React.Fragment>
                                                                      {pharmacy?.estRetailPrice > 0 ? (
                                                                        <React.Fragment>
                                                                          <div className="text-center">
                                                                            <p>
                                                                              <sup>$&nbsp;</sup>
                                                                              <s>
                                                                                {pharmacy?.estRetailPrice?.toLocaleString(
                                                                                  undefined,
                                                                                  {
                                                                                    minimumFractionDigits: 2,
                                                                                    maximumFractionDigits: 2,
                                                                                  }
                                                                                )}
                                                                              </s>
                                                                            </p>
                                                                            <span>
                                                                              est. cash price
                                                                            </span>
                                                                          </div>
                                                                        </React.Fragment>
                                                                      ) : (
                                                                        <React.Fragment>
                                                                          <h5 className="text-center fw-bold">
                                                                            N/A
                                                                          </h5>
                                                                        </React.Fragment>
                                                                      )}
                                                                    </React.Fragment>}

                                                                </div>


                                                                <div className="col-6 col-sm-4 col-md-3 col-lg-3">
                                                                  <div className="text-center">
                                                                    <p>
                                                                      <sup>$&nbsp;</sup>

                                                                      {getCrxBestPrice(pharmacy)
                                                                      }
                                                                    </p>
                                                                    <span>
                                                                      {(pharmacy?.description == "with discount card" || pharmacy?.description == "with Single Care") ? "with RxLink Offer" : pharmacy?.description}
                                                                      {/* with RxLink discount */}
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                                <div className="col-12 col-sm-12 col-md-2 col-lg-2">
                                                                  {/* <MotionButton> */}
                                                                  <button
                                                                    aria-label={'Select pharmacy ' + pharmacy?.pharmacyName}
                                                                    className="btn_success"
                                                                    onClick={() => selectMap(pharmacy)}
                                                                  >
                                                                    Select
                                                                  </button>
                                                                  {/* </MotionButton> */}
                                                                </div>

                                                                <hr className="m-0"></hr>
                                                              </React.Fragment>
                                                            )}
                                                        </motion.div>
                                                      )
                                                    })
                                                  }
                                                </React.Fragment>
                                              )
                                            }
                                          }
                                        )}
                                      </div>
                                    </AnimatePresence>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </AnimatePresence>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </motion.section>
      )

      }

      {selectedDrug && <EditMedicationModal
        isVisibleEditModal={isVisibleEditModal}
        setIsVisibleEditModal={setIsVisibleEditModal}
        selectedDrug={selectedDrug}
        setSelectedDrug={setSelectedDrug}
        savePrescription={savePrescription}
      />}
    </React.Fragment>
  );
};
